import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import I18NextChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import Cookies from 'js-cookie';
import { merge } from 'lodash';
import includes from 'lodash/includes';
import { initReactI18next } from 'react-i18next';

import { IS_DEVELOPMENT, IS_PRODUCTION } from '@/config/env';
import { type LocalizationSettings } from '@/core/app-ctx/AppSettings';
import { type LocalizationOverrides, type OVERRIDEABLE_NAMESPACES } from '@/core/localization/mod';

/* The Supported Regions, Languages and Currencies */
export const SUPPORTED_REGIONS = [
    'AT',
    'DE',
    'NL',
    'FR',
    'BE',
    'CH',
    'CZ',
    'GB',
    'IT',
    'LI',
    'LU',
    'PL',
    'SE',
    'SK',
] as const;
export const DEVELOPMENT_LANGUAGES = ['de', 'en', 'fr', 'it', 'nl', 'sv', 'sk', 'pl', 'cs', 'cimode'] as const;
// These should be the same as the supported-languages in the backend application.properties
export const SUPPORTED_LANGUAGES = ['de', 'en', 'sk', 'cs', 'fr'] as const;
export const SUPPORTED_CURRENCIES = ['EUR', 'CHF', 'CZK', 'HUF', 'PLN', 'GBP', 'SEK'] as const;
export const COUNTRIES_ON_TOP = ['AT', 'DE', 'CH', 'NL', 'LU', 'SE'] as const;
export const SUPPORTED_COUNTRIES = [
    'AD',
    'AE',
    'AF',
    'AG',
    'AL',
    'AM',
    'AO',
    'AR',
    'AT',
    'AU',
    'AZ',
    'BA',
    'BB',
    'BD',
    'BE',
    'BF',
    'BG',
    'BH',
    'BI',
    'BJ',
    'BN',
    'BO',
    'BR',
    'BS',
    'BT',
    'BW',
    'BY',
    'BZ',
    'CA',
    'CD',
    'CF',
    'CG',
    'CH',
    'CI',
    'CL',
    'CM',
    'CN',
    'CO',
    'CR',
    'CU',
    'CV',
    'CY',
    'CZ',
    'DE',
    'DJ',
    'DK',
    'DM',
    'DO',
    'DZ',
    'EC',
    'EE',
    'EG',
    'ER',
    'ES',
    'ET',
    'FI',
    'FJ',
    'FM',
    'FR',
    'GA',
    'GB',
    'GD',
    'GE',
    'GH',
    'GM',
    'GN',
    'GQ',
    'GR',
    'GT',
    'GW',
    'GY',
    'HN',
    'HR',
    'HT',
    'HU',
    'ID',
    'IE',
    'IL',
    'IN',
    'IQ',
    'IR',
    'IS',
    'IT',
    'JM',
    'JO',
    'JP',
    'KE',
    'KG',
    'KH',
    'KI',
    'KM',
    'KN',
    'KP',
    'KR',
    'KW',
    'KZ',
    'LA',
    'LB',
    'LC',
    'LI',
    'LK',
    'LR',
    'LS',
    'LT',
    'LU',
    'LV',
    'LY',
    'MA',
    'MC',
    'MD',
    'ME',
    'MG',
    'MH',
    'MK',
    'ML',
    'MM',
    'MN',
    'MR',
    'MT',
    'MU',
    'MV',
    'MW',
    'MX',
    'MY',
    'MZ',
    'NA',
    'NE',
    'NG',
    'NI',
    'NL',
    'NO',
    'NP',
    'NR',
    'NZ',
    'OM',
    'PA',
    'PE',
    'PG',
    'PH',
    'PK',
    'PL',
    'PT',
    'PW',
    'PY',
    'QA',
    'RO',
    'RS',
    'RU',
    'RW',
    'SA',
    'SB',
    'SC',
    'SD',
    'SE',
    'SG',
    'SI',
    'SK',
    'SL',
    'SM',
    'SN',
    'SO',
    'SR',
    'SS',
    'ST',
    'SV',
    'SY',
    'SZ',
    'TD',
    'TG',
    'TH',
    'TJ',
    'TL',
    'TM',
    'TN',
    'TO',
    'TR',
    'TT',
    'TV',
    'TZ',
    'UA',
    'UG',
    'US',
    'UY',
    'UZ',
    'VA',
    'VC',
    'VE',
    'VN',
    'VU',
    'WS',
    'YE',
    'ZA',
    'ZM',
    'ZW',
] as const;

/**
 * Return the fraction digits for the given currency.
 * When we add a new currency, we need to add it here too.
 *
 * The data can be found, for example, here: https://en.wikipedia.org/wiki/ISO_4217#Active_codes_(List_One)
 * but it's not up-to-date.
 * E.g., the Hungarian forint subunit is not used anymore, so it has effectively 0 fraction digits.
 */
export const currencyMaximumFractionDigits = (currency: Currency) => {
    switch (currency) {
        case 'HUF':
        case 'CZK':
        case 'SEK': {
            return 0;
        }
        default: {
            return 2;
        }
    }
};

export const supportedLanguages = () =>
    IS_PRODUCTION
        ? SUPPORTED_LANGUAGES
        : IS_DEVELOPMENT
          ? DEVELOPMENT_LANGUAGES
          : DEVELOPMENT_LANGUAGES.filter(value => value !== 'cimode');

export const isSupportedRegion = (region: string): region is Region => includes(SUPPORTED_REGIONS, region);

export const SORTED_COUNTRIES = [
    ...COUNTRIES_ON_TOP,
    ...SUPPORTED_COUNTRIES.filter(country => !includes(COUNTRIES_ON_TOP, country)),
];

/* Infer type from SUPPORTED_* arrays */
export type Region = (typeof SUPPORTED_REGIONS)[number];
export type Language = (typeof DEVELOPMENT_LANGUAGES)[number];
export type Currency = (typeof SUPPORTED_CURRENCIES)[number];

/* The default region, language and currency to use */
export const DEFAULT_REGION: Region = 'AT';
export const DEFAULT_LANGUAGE: Language = 'de';
export const DEFAULT_CURRENCY: Currency = 'EUR';

export const CURRENCY_COOKIE = 'BUSFINDER_CURRENCY';
export const LOCALE_COOKIE = 'BUSFINDER_LOCALE';

export async function initI18Next(
    { languageDetectionOrder }: LocalizationSettings,
    localizationOverrides: LocalizationOverrides,
) {
    // Init i18next only once
    if (!i18next.isInitialized) {
        // eslint-disable-next-line import/no-named-as-default-member
        await i18next
            .use(LanguageDetector)
            .use(initReactI18next)
            .use(I18NextChainedBackend)
            // https://www.i18next.com/overview/configuration-options
            .init({
                // Fallback to dev language
                fallbackLng: 'en',
                // Load all language files (ex. given de-AT -> [de-AT, de, en])
                load: 'all',
                // Set the supported languages
                supportedLngs: DEVELOPMENT_LANGUAGES,
                // Allow non-explicit supported languages. Does not warn about empty resources for en-AT for example
                nonExplicitSupportedLngs: true,
                // Namespaces to load
                ns: [
                    'translation',
                    'routes',
                    'localization',
                    'validations',
                    'countries',
                    'units',
                    'tour',
                    'editor',
                    'backend',
                    'integration_extras',
                ],
                // Default namespace to use if not defined explicitly
                defaultNS: 'translation',
                // Return empty strings instead of null if a key does not exist
                returnNull: false,
                // returnObjects: true,
                // Dynamic import of resources via vite
                backend: {
                    backends: [
                        resourcesToBackend((lng, ns, cb) => {
                            import(`../assets/locales/${lng}/${ns}.json`)
                                .then(({ default: resources }) => {
                                    // Merge the Integration overrides
                                    const overrides =
                                        localizationOverrides[lng as Language]?.[
                                            ns as (typeof OVERRIDEABLE_NAMESPACES)[number]
                                        ];

                                    merge(resources, overrides);

                                    cb(null, resources);
                                })
                                .catch(error => {
                                    // Atm we only have translation files for language codes.
                                    // en-AT for example won't return anything. Suppress these errors.
                                    if (lng.includes('-')) cb(null, {});
                                    else cb(error, null);
                                });
                        }),
                    ],
                },
                // Browser Language Detection
                detection: {
                    order: ['querystring', ...(languageDetectionOrder ?? ['cookie', 'htmlTag', 'navigator']), 'cookie'],
                    lookupCookie: LOCALE_COOKIE,
                    lookupQuerystring: 'language',
                    caches: ['sessionStorage'],
                },
                // React config
                react: {
                    useSuspense: true,
                },
                // Debug in dev env
                debug: IS_DEVELOPMENT,
                // Separators (keep consistent with i18next-parser config)
                pluralSeparator: '_',
                contextSeparator: '_',
                nsSeparator: ':',
                keySeparator: '.',
                interpolation: {
                    defaultVariables: {
                        identifier: '',
                    },
                },
                // Treat empty strings as missing translation (fallback to fallbackLng)
                returnEmptyString: false,
            });
    }

    return i18next;
}

export const setLocalizationCookie = (key: string, value: string) => {
    Cookies.set(key, value, {
        secure: true,
        expires: 365,
        sameSite: 'strict',
    });
};

export function isValidLanguage(language: string): language is Language {
    return includes(SUPPORTED_LANGUAGES, language);
}

export function isValidRegion(region: string): region is Region {
    return includes(SUPPORTED_REGIONS, region);
}

export function isValidCurrency(currency: string): currency is Currency {
    return includes(SUPPORTED_CURRENCIES, currency);
}

export function getSavedLocalizationValues() {
    const savedValues: { language?: Language; region?: Region; currency?: Currency } = {};

    const localeCookie = Cookies.get(LOCALE_COOKIE) ?? '';
    const [language, region] = localeCookie.split('-');
    if (isValidLanguage(language)) savedValues.language = language;
    if (isValidRegion(region)) savedValues.region = region;

    const currencyCookie = Cookies.get(CURRENCY_COOKIE) ?? '';
    if (isValidCurrency(currencyCookie)) savedValues.currency = currencyCookie;

    return savedValues;
}

export const getSavedCurrency = (): Currency => {
    // check if supported currency is already in cookie and return it
    const cookieCurrency = Cookies.get(CURRENCY_COOKIE);
    if (cookieCurrency && includes(SUPPORTED_CURRENCIES, cookieCurrency)) return cookieCurrency as Currency;
    return DEFAULT_CURRENCY;
};
