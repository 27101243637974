import { type SupportedColorScheme } from '@mui/material';

import {
    DEVELOPMENT_LANGUAGES,
    SUPPORTED_CURRENCIES,
    SUPPORTED_REGIONS,
    type Currency,
    type Language,
    type Region,
} from '@/config/localization';

interface AuthenticationSettings {
    // Disables the registration and login UI and everything else which is connected to authentication
    disableAuthentication: boolean;
}

function defaultAuthSettings(): AuthenticationSettings {
    return {
        disableAuthentication: false,
    };
}

interface TutorialSettings {
    // Disables the search form tour
    disableSearchFormTour: boolean;
}

function defaultTutorialSettings(): TutorialSettings {
    return {
        disableSearchFormTour: false,
    };
}

export interface ThemeSettings {
    // Force theme color scheme
    forceColorScheme: SupportedColorScheme | null;
}

export function defaultThemeSettings(): ThemeSettings {
    return {
        forceColorScheme: null,
    };
}

interface BookingSettings {
    // Instead of booking, users can reserve a bus. They receive an email with a link where they can complete the booking
    enableReservation: boolean;
    disableDriverRoomsChoice: boolean;
    // Whether amending a booking is disabled for customers. This does not disable operators from amending a booking or an offer.
    disableCustomerBookingAmend: boolean;
}

function defaultBookingSettings(): BookingSettings {
    return {
        enableReservation: false,
        disableDriverRoomsChoice: false,
        disableCustomerBookingAmend: false,
    };
}

interface SearchResultsSettings {
    // Completely disables the result filter UI. Results are always sorted in the `recommended` order
    disableResultFilter: boolean;
    // Disables trip companies
    disableTripCompanies: boolean;
    // The user is required to enter their name before they can see any results
    requireCustomerName: boolean;
    // The user is required to enter their email before they can see any results
    requireCustomerMail: boolean;
    // Shows prices for school; Adds `I am a school` checkbox in the search form to see prices for schools
    // Still requires the user to be a verified school or a reseller
    applicableSchool: boolean;
    // Whether to ignore the usual limitation of maximal arrival and return kilometers for buses on this integration.
    // This setting is handled in the backend.
    ignoreArrivalAndReturnKm: boolean;
    // Whether we automatically send an email to the customer if he searched for buses but did not book within 24 hours
    // This setting is handled in the backend.
    sendSearchResultsReminderMail: boolean;
    // The threshold for the number of buses to show the limited availability form on the search results page.
    // So far only Emile Weber wanted to change this number to 0
    limitedBusFormThreshold: number;
}

function defaultSearchResultsSettings(): SearchResultsSettings {
    return {
        disableResultFilter: false,
        disableTripCompanies: false,
        requireCustomerName: false,
        requireCustomerMail: false,
        applicableSchool: false,
        ignoreArrivalAndReturnKm: false,
        sendSearchResultsReminderMail: false,
        limitedBusFormThreshold: 1,
    };
}

export interface LocalizationSettings {
    // Disables the language switcher UI
    disableLanguageSwitcher: boolean;
    // Disables the region switcher UI
    disableRegionSwitcher: boolean;
    // Disables the currency switcher UI
    disableCurrencySwitcher: boolean;
    // Optional default language.
    // If no default language is set and the user hasn't selected a language, we detect the language from the browser.
    defaultLanguage: Language | null;
    // Optional language detection order
    languageDetectionOrder: string[] | null;
    // Optional default region.
    // If no default region is set and the user hasn't selected a region, we detect the region from the browser.
    defaultRegion: Region | null;
    // Optional default currency.
    defaultCurrency: Currency | null;
}

function defaultLocalizationSettings(): LocalizationSettings {
    return {
        disableLanguageSwitcher: false,
        disableRegionSwitcher: false,
        disableCurrencySwitcher: false,
        defaultLanguage: null,
        defaultRegion: null,
        defaultCurrency: null,
        languageDetectionOrder: null,
    };
}

interface BrandingSettings {
    // Disables the BusFinder Logo
    disableLogo: boolean;
    // Disable Cookie Banner. We still track the user, if GA cookies are detected
    disableCookieBanner: boolean;
    // Disables the CO2 feature, hides CO2 related texts and SVGs
    disableCo2Feature: boolean;
}

function defaultBrandingSettings(): BrandingSettings {
    return {
        disableLogo: false,
        disableCookieBanner: false,
        disableCo2Feature: false,
    };
}

function isInOrNull(value: string | null | undefined, values: readonly string[]): string | null {
    return value && values.includes(value) ? value : null;
}

function isNonEmptyArrayOrNull(values: string | string[] | null | undefined): string[] | null {
    if (!values) return null;
    if (Array.isArray(values) && values.length > 0) return values;
    return null;
}

export class AppSettings {
    public static default(): AppSettings {
        return new AppSettings({
            authentication: defaultAuthSettings(),
            tutorial: defaultTutorialSettings(),
            booking: defaultBookingSettings(),
            searchResults: defaultSearchResultsSettings(),
            localization: defaultLocalizationSettings(),
            branding: defaultBrandingSettings(),
            theme: defaultThemeSettings(),
        });
    }

    public readonly authentication: AuthenticationSettings;

    public readonly tutorial: TutorialSettings;

    public readonly theme: ThemeSettings;

    public readonly booking: BookingSettings;

    public readonly searchResults: SearchResultsSettings;

    public readonly localization: LocalizationSettings;

    public readonly branding: BrandingSettings;

    constructor(json: Record<string, any> = {}) {
        this.authentication = json.authentication ?? defaultAuthSettings();
        this.tutorial = json.tutorial ?? defaultTutorialSettings();
        this.booking = json.booking ?? defaultBookingSettings();
        this.searchResults = json.searchResults ?? defaultSearchResultsSettings();
        this.theme = json.theme ?? defaultThemeSettings();

        if ((this.theme.forceColorScheme as any) === '') this.theme.forceColorScheme = null;

        const localization = json.localization ?? defaultLocalizationSettings();

        this.localization = {
            ...localization,
            defaultLanguage: isInOrNull(localization.defaultLanguage, DEVELOPMENT_LANGUAGES),
            defaultRegion: isInOrNull(localization.defaultRegion, SUPPORTED_REGIONS),
            defaultCurrency: isInOrNull(localization.defaultCurrency, SUPPORTED_CURRENCIES),
            languageDetectionOrder: isNonEmptyArrayOrNull(localization.languageDetectionOrder),
        };

        this.branding = json.branding ?? defaultBrandingSettings();
    }
}
